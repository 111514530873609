import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const MarketsDefault = Loadable(lazy(() => import('views/dashboard/Markets')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/Typography')));
const UtilsStrategies = Loadable(lazy(() => import('views/Strategies')));
const UtilsStrategiesPage = Loadable(lazy(() => import('views/StrategiesPage')));
const UtilsAccountsPage = Loadable(lazy(() => import('views/AccountPage')));
const UtilsAPIPage = Loadable(lazy(() => import('views/APIPage')));
const UtilsCode = Loadable(lazy(() => import('views/Code2')));
const UtilsPrediction = Loadable(lazy(() => import('views/Prediction')));
const UtilsNews = Loadable(lazy(() => import('views/News')));
const UtilsSquare = Loadable(lazy(() => import('views/Square')));
const UtilsCodeOld = Loadable(lazy(() => import('views/Code')));
const UtilsChat = Loadable(lazy(() => import('views/Chat')));
//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard',
                '/markets',
                '/utils/accounts/:id',
                '/utils/apis',
                '/utils/strategies',
                '/utils/strategies/:id',
                '/utils/apis/:id',
                '/utils/code',
                '/utils/code_old',
                '/utils/prediction',
                '/utils/news',
                '/utils/square',
                '/utils/chat',
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route exact path="/dashboard" component={DashboardDefault} />
                        <Route exact path="/markets" component={MarketsDefault} />
                        <Route exact path="/utils/accounts/:id" component={UtilsAccountsPage} />
                        <Route exact path="/utils/apis" component={UtilsTypography} />
                        <Route exact path="/utils/strategies" component={UtilsStrategies} />
                        <Route exact path="/utils/strategies/:id" component={UtilsStrategiesPage} />
                        <Route exact path="/utils/apis/:id" component={UtilsAPIPage} />
                        <Route exact path="/utils/code" component={UtilsCode} />
                        <Route exact path="/utils/prediction" component={UtilsPrediction} />
                        <Route exact path="/utils/news" component={UtilsNews} />
                        <Route exact path="/utils/square" component={UtilsSquare} />
                        <Route exact path="/utils/code_old" component={UtilsCodeOld} />
                        <Route exact path="/utils/chat" component={UtilsChat} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
