import React from 'react';

// material-ui
import { useTheme } from '@material-ui/styles';
import logo from 'assets/images/logo.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from '.assets/images/logo-dark.svg';
 * 
 *
 */

//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    const theme = useTheme();

    return (
        <img src={"https://quantle.io/favicon.svg"} alt="Surmount" height="30" width="30"/>
    );
};

export default Logo;
